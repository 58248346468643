







































































































import { Component, Vue } from 'vue-property-decorator';
import { helpers, minLength, required } from 'vuelidate/lib/validators';
import AlertComponent from '@/components/AlertComponent.vue';
import { validationMixin } from 'vuelidate';
import AlertType from '@/utils/enums/AlertType';
import { Getter, namespace } from 'vuex-class';
import ButtonComponent from '@/components/ButtonComponent.vue';
import InputText from '@/components/InputText.vue';
import EmailResponse from '@/utils/types/sign-in/EmailResponse';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import AuthProvider from '@/models/graphql/AuthProvider';
import Community from '@/models/graphql/Community';
import { EMAIL } from '@/utils/constants/Regex';
import AuthPageGuard from '@/guards/AuthPageGuard';
import { FeatureKeys } from '@/utils/enums/FeatureKeys';
import CommunityFeature from '@/models/graphql/CommunityFeature';

const authenticationStore = namespace('AuthenticationStore');
const authProviderStore = namespace('AuthProviderStore');
const emailValidation = helpers.regex('email', EMAIL);

@Component({
  components: {
    ButtonComponent,
    InputText,
    AlertComponent,
    ButtonIconComponent,
    FontAwesomeComponent,
  },
  mixins: [validationMixin],
  validations: {
    form: {
      email: {
        required,
        minLength: minLength(3),
        emailValidation,
      },
    },
  },
  beforeRouteEnter: AuthPageGuard.beforeEnter,
})
export default class SignInEnterEmail extends Vue {
  @Getter
  private readonly community!: Community;

  @authenticationStore.State
  private readonly authEmail!: string | null;

  @authenticationStore.Action
  private checkEmail!: (payload: {
    email: string;
  }) => Promise<EmailResponse>;

  @authenticationStore.Mutation
  private setAuthEmail!: (email: string | null) => void;

  @authenticationStore.Action
  private logout!: () => Promise<void>;

  @authProviderStore.Action
  private loadAuthProviders!: () => Promise<AuthProvider[]>;

  @Getter
  private readonly isUnifyExhibitorPortal!: boolean;

  private alertType = AlertType;

  private isLoading = false;

  private emailError = false;

  private showError = false;

  private emailServiceError = false;

  private disableEmail = false;

  private authProviders: AuthProvider[] | null = null;

  @Getter
  private readonly featureByKey!: (key: FeatureKeys) => CommunityFeature;

  private form = {
    email: '',
  };

  private get signInText(): string {
    if (this.$te('app.sign-in.enter-email.sign-in-text')) {
      return `${this.$t('app.sign-in.enter-email.sign-in-text')}`;
    }
    return '';
  }

  private get isSignupEnabled(): boolean {
    return this.featureByKey(FeatureKeys.USER_SIGNUP)
      && this.featureByKey(FeatureKeys.USER_SIGNUP).enabled;
  }

  created(): void {
    if (this.authEmail) {
      this.form.email = this.authEmail;
    }
    this.loadAuthProviders()
      .then((response) => {
        if (response) {
          this.authProviders = response;
        }
      });
  }

  private emailValidation(): void {
    if (this.$v) {
      this.emailError = this.$v.form.email ? this.$v.form.email.$invalid : false;
    }
  }

  private onSubmit(): void {
    this.emailValidation();
    this.disableEmail = true;
    this.showError = true;
    this.emailServiceError = false;
    this.isLoading = true;
    if (this.form.email
      && this.form.email !== ''
      && !this.$data.emailError
    ) {
      this.checkEmail({
        email: this.form.email,
      })
        .then((response: EmailResponse) => {
          if (response.success) {
            this.setAuthEmail(this.form.email);
            if (response.canProceedWithAccountCreation) {
              if (!this.isUnifyExhibitorPortal && this.isSignupEnabled) {
                this.$router.push({ name: 'sign-up' });
              } else {
                this.$router.push({ name: 'no-sign-up' });
              }
            }
            if (response.canProceedWithPasswordLogin || response.canProceedWithMagicLinkViaEmail) {
              this.$router.push({ name: 'enter-password' });
            }
            if (!response.canProceedWithAccountCreation
              && !response.canProceedWithPasswordLogin
              && !response.canProceedWithMagicLinkViaEmail
            ) {
              this.$router.push({ name: 'no-sign-up' });
            }
          } else if (response.message && response.message === 'requestError') {
            this.emailServiceError = true;
          } else {
            this.emailError = true;
          }
          this.disableEmail = false;
          this.isLoading = false;
        })
        .catch(() => {
          this.emailServiceError = true;
          this.disableEmail = false;
          this.isLoading = false;
        });
    } else {
      this.disableEmail = false;
      this.isLoading = false;
    }
  }

  private onAuthProviderClick(id: number | undefined): void {
    if (id) {
      // eslint-disable-next-line max-len
      window.location.href = `${process.env.VUE_APP_SERVICES_ACCOUNT_URL}oauth/start-flow?community_code=${this.community.code}&login_provider_id=${id}`;
    }
  }

  private onGuestClick(): void {
    this.logout()
      .then(() => {
        this.$router.push({ path: '/' });
      });
  }
}
